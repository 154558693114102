var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"track"},[_c('div',{staticClass:"main"},[_c('aside',{staticClass:"leftbar"},[_c('section',{staticClass:"track-controls"},[_c('h4',[_vm._v("Track controls")]),_c('div',{staticClass:"track-control-selector-container"},[_c('button',{staticClass:"track-control-selector sound",class:_vm.trackControlsActiveTab == 'sound'
                                ? 'active'
                                : '',on:{"click":function($event){return _vm.setTrackControlSelection('sound')}}},[_c('font-awesome-icon',{attrs:{"icon":"volume-up"}})],1),_c('button',{staticClass:"track-control-selector effects",class:_vm.trackControlsActiveTab == 'effects'
                                ? 'active'
                                : '',on:{"click":function($event){return _vm.setTrackControlSelection('effects')}}},[_c('font-awesome-icon',{attrs:{"icon":"weight"}})],1)]),_c('div',{staticClass:"track-control-element-container"},[_c('div',{staticClass:"track-control-element sound",class:_vm.trackControlsActiveTab == 'sound'
                                ? 'active'
                                : ''},[_c('article',{staticClass:"synth-control"},[_c('header',[_vm._v("Volume")]),_c('el-slider',{attrs:{"min":0,"max":100},model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=$$v},expression:"volume"}})],1),_c('article',{staticClass:"synth-control"},[_c('header',[_vm._v("Synth model")]),_c('el-select',{model:{value:(_vm.synthModel),callback:function ($$v) {_vm.synthModel=$$v},expression:"synthModel"}},[_c('el-option',{key:"synth",attrs:{"value":"synth","label":"Synth"}},[_vm._v("Synth")]),_c('el-option',{key:"monoSynth",attrs:{"value":"monoSynth","label":"Mono Synth"}},[_vm._v("Mono Synth")]),_c('el-option',{key:"sampler",attrs:{"value":"sampler","label":"Sampler"}},[_vm._v("Sampler")])],1)],1),(_vm.synthModel == 'sampler')?_c('article',{staticClass:"sample-control"},[_c('el-input',{model:{value:(_vm.sample),callback:function ($$v) {_vm.sample=$$v},expression:"sample"}}),_c('button',{staticClass:"no-highlight",on:{"click":function($event){_vm.sampleManagerVisible = true}}},[_vm._v(" Sample Manager ")]),_c('SampleWaveform',{attrs:{"url":_vm.sample,"showControls":false}})],1):_vm._e(),(_vm.synthModel != 'sampler')?_c('article',{staticClass:"synth-control"},[_c('SynthParameters',{attrs:{"model":_vm.synthModel},on:{"synthOptionChange":_vm.initializeSynth}})],1):_vm._e()]),_c('div',{staticClass:"track-control-element effects",class:_vm.trackControlsActiveTab == 'effects'
                                ? 'active'
                                : ''},[_c('article',{staticClass:"effect distortion"},[_c('header',[_vm._v(" Distortion "),_c('el-switch',{model:{value:(_vm.effects.distortion.enabled),callback:function ($$v) {_vm.$set(_vm.effects.distortion, "enabled", $$v)},expression:"effects.distortion.enabled"}})],1),_c('div',{staticClass:"controls"},[_c('div',{staticClass:"control"},[_c('p',{staticClass:"label"},[_vm._v("Amount")]),_c('div',{staticClass:"value"},[_c('el-slider',{attrs:{"min":0,"max":100},model:{value:(
                                                _vm.effects.distortion
                                                    .properties.distortion
                                            ),callback:function ($$v) {_vm.$set(_vm.effects.distortion
                                                    .properties, "distortion", $$v)},expression:"\n                                                effects.distortion\n                                                    .properties.distortion\n                                            "}})],1)])])]),_c('article',{staticClass:"effect delay"},[_c('header',[_vm._v(" Delay "),_c('el-switch',{model:{value:(_vm.effects.delay.enabled),callback:function ($$v) {_vm.$set(_vm.effects.delay, "enabled", $$v)},expression:"effects.delay.enabled"}})],1),_c('div',{staticClass:"controls"},[_c('div',{staticClass:"control"},[_c('p',{staticClass:"label"},[_vm._v("Time")]),_c('div',{staticClass:"value"},[_c('el-slider',{attrs:{"min":0,"max":100},model:{value:(
                                                _vm.effects.delay.properties
                                                    .delayTime
                                            ),callback:function ($$v) {_vm.$set(_vm.effects.delay.properties
                                                    , "delayTime", $$v)},expression:"\n                                                effects.delay.properties\n                                                    .delayTime\n                                            "}})],1)]),_c('div',{staticClass:"control"},[_c('p',{staticClass:"label"},[_vm._v("Feedback")]),_c('div',{staticClass:"value"},[_c('el-slider',{attrs:{"min":0,"max":100},model:{value:(
                                                _vm.effects.delay.properties
                                                    .feedback
                                            ),callback:function ($$v) {_vm.$set(_vm.effects.delay.properties
                                                    , "feedback", $$v)},expression:"\n                                                effects.delay.properties\n                                                    .feedback\n                                            "}})],1)])])]),_c('article',{staticClass:"effect reverb"},[_c('header',[_vm._v(" Reverb "),_c('el-switch',{model:{value:(_vm.effects.reverb.enabled),callback:function ($$v) {_vm.$set(_vm.effects.reverb, "enabled", $$v)},expression:"effects.reverb.enabled"}})],1),_c('div',{staticClass:"controls"},[_c('div',{staticClass:"control"},[_c('p',{staticClass:"label"},[_vm._v("Decay")]),_c('div',{staticClass:"value"},[_c('el-slider',{attrs:{"min":0,"max":100},model:{value:(
                                                _vm.effects.reverb.properties
                                                    .decay
                                            ),callback:function ($$v) {_vm.$set(_vm.effects.reverb.properties
                                                    , "decay", $$v)},expression:"\n                                                effects.reverb.properties\n                                                    .decay\n                                            "}})],1)])])])])])]),_c('section',{staticClass:"trigger-controls"},[(_vm.selectedDetailTrigger)?[_c('article',{staticClass:"trigger-note"},[_c('h4',[_vm._v(" Trigger controls: "),_c('u',[_vm._v("Trigger "+_vm._s(_vm.selectedDetailTrigger.index))])]),_c('div',{staticClass:"note"},[_c('el-select',{staticClass:"note-value",model:{value:(_vm.selectedDetailTriggerNoteValue),callback:function ($$v) {_vm.selectedDetailTriggerNoteValue=$$v},expression:"selectedDetailTriggerNoteValue"}},_vm._l((_vm.notes),function(note){return _c('el-option',{key:note,attrs:{"value":note}},[_vm._v(" "+_vm._s(note)+" ")])}),1),_c('el-input',{staticClass:"note-height",attrs:{"controls-position":"right"},model:{value:(_vm.selectedDetailTriggerNoteHeight),callback:function ($$v) {_vm.selectedDetailTriggerNoteHeight=$$v},expression:"selectedDetailTriggerNoteHeight"}})],1)])]:_vm._e()],2)]),_c('main',{staticClass:"main-part"},[_c('section',{staticClass:"pattern"},_vm._l((16),function(i){return _c('div',{key:i,staticClass:"trigger",class:_vm.sequencerPosition == i
                            ? 'current-step'
                            : _vm.isActive(i)
                            ? 'active'
                            : _vm.isDetail(i)
                            ? 'detail'
                            : ''},[_c('a',{staticClass:"trigger-point",on:{"click":function($event){return _vm.toggleTrigger(i)}}},[_vm._v(_vm._s(i))]),_c('a',{staticClass:"settings",on:{"click":function($event){return _vm.setTriggerSettings(i)}}},[_c('font-awesome-icon',{attrs:{"icon":"cog"}})],1)])}),0)]),_c('aside',{staticClass:"rightbar"})]),_c('el-dialog',{attrs:{"title":"Sample Manager","visible":_vm.sampleManagerVisible,"width":"75%"},on:{"update:visible":function($event){_vm.sampleManagerVisible=$event}}},[_c('SampleManager',{on:{"useSample":function($event){_vm.sample = $event}}}),_c('template',{staticClass:"dialog-footer",slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.sampleManagerVisible = false}}},[_vm._v("Cancel")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }